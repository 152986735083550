<template>
	<div class="slider">
		<div class="slider__arrow" @click="prev" >
			<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.78906 2.3874L3.80724 6.36922L7.78906 10.351L6.28073 11.8594L0.790573 6.36922L6.28073 0.879067L7.78906 2.3874Z"/>
            </svg>

		</div>
		<div class="slide-container">
			<slick ref="slick" class="slider__carousel" :options="settings" >
				<div class="slider-item" v-for="(item, key) in data" :key="key" :class="`slide-count-${key}`">
					<slot :slide="item"></slot>
				</div>
			</slick>
		</div>
		<div class="slider__arrow right" @click="next" >
			<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.78906 2.3874L3.80724 6.36922L7.78906 10.351L6.28073 11.8594L0.790573 6.36922L6.28073 0.879067L7.78906 2.3874Z"/>
            </svg>

		</div>
	</div>
</template>

<script>
	import Slick from 'vue-slick';
	import 'slick-carousel/slick/slick.css';
	import { library } from '@fortawesome/fontawesome-svg-core'
	import {
		faChevronLeft,
		faChevronRight
	} from '@fortawesome/free-solid-svg-icons'

	library.add(faChevronLeft, faChevronRight)

	export default {
		name: 'Slider',
		components: { Slick },
		props: {
			data: {
				type: Array,
				default: () => ([])
			},
			hasArrow:{
				type: Boolean,
			default: true
			},
			slidesToShow:{
				type: [Number, String],
			default:4
			}

		},
		data() {
			return {}
		},
		computed: {
			sliderV2Condition() {
				return this.data.list.length === 3
			},
			settings(){
				let responsive = []
				if(this.slidesToShow >= 4){
					responsive = [{
						breakpoint: 1045,
						settings: {
							slidesToShow: 3,
						}
					},
                    {
						breakpoint: 992,
						settings: {
							slidesToShow: 3,
                            autoplay: true,
                            arrows: false,
							autoplaySpeed: 3000
						}
					},
					{
						breakpoint: 800,
						settings: {
							slidesToShow: 2,
                            arrows: false,
                            autoplay: true,
							autoplaySpeed: 3000
						}
					},
                    {
						breakpoint: 540,
						settings: {
							slidesToShow: 1,
							arrows: false,
							autoplay: true,
							autoplaySpeed: 3000
						}
					}]
				}

                if(this.slidesToShow == 1){
					responsive = [
                    {
						breakpoint: 992,
						settings: {
                            autoplay: true,
                            arrows: false,
							autoplaySpeed: 3000
						}
					}]
				}


				return  {
					arrows: false,
					slidesToShow: this.slidesToShow,
					slidesToScroll: 1,
					draggable: true,
					infinite: true,
					responsive:responsive
				}
			}
		},

		methods: {
			prev() {
				this.$refs.slick.prev();
			},
			next() {
				this.$refs.slick.next();
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import '../styles/main.scss';
	.slider{
		
		position:relative;

		.slider-item{
			padding: 10px;
		}
		.slider__arrow{
			display:none;
		}
		@media (min-width: 992px) {

			.slider__arrow {
				display: flex;
                align-items: center;
                justify-content: center;
				cursor:pointer;
				position: absolute;
				top: calc(50% - 1rem);
				left: -30px;
                border-radius: 50%;
                svg {
                    fill: #000;
                    width: 30px;
                    height: 30px;
                }
				&.right {
					left: auto;
					right: -30px;
                    transform: rotate(180deg);
				}
			}	
		}
		
	}
</style>
